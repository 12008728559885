import classes from './MainNavigation.module.css';
import {NavLink} from "react-router-dom";

const MainNavigation = () => {
  return (
    <header className={classes.header}>
        <div className={classes.logo}>Great Quotes</div>
        <nav className={classes.nav}>
            <ul>
                {/* Using Nav Link insted of link so we can add active css style. And NavLink insted of <A> so we dontrealod the page */}
                <li><NavLink to='/quotes' activeClassName={classes.active}>All Quotes</NavLink></li>
                <li><NavLink to='/new-quotes' activeClassName={classes.active}>Add a Quote</NavLink></li>
            </ul>
        </nav>
    </header>
  );
};

export default MainNavigation;