import {useCallback, useEffect, useState} from 'react';

import classes from './Comments.module.css';
import NewCommentForm from './NewCommentForm';
import {useParams} from "react-router";
import useHttp from "../../hooks/use-http";
import {getAllComments} from "../../lib/api";
import LoadingSpinner from "../UI/LoadingSpinner";
import CommentsList from "./CommentsList";

const Comments = () => {
    const [isAddingComment, setIsAddingComment] = useState(false);

    /*Getting quote ID from url */
    const params = useParams();

    /* Object destruction getting quoteID beacuse we use it inside useEffect */
    const { quoteId } = params;

    console.log(params);

    const startAddCommentHandler = () => {
        setIsAddingComment(true);
    };

    /* Setting up comment get request */
    const {sendRequest, status, data: loadedComments } = useHttp(getAllComments);

    /* Sending Request Each time a comment change */
    useEffect(() => {
        sendRequest(quoteId);
    }, [quoteId, sendRequest])

    /* Added comment handler when a comment is added, we want to fecth the comment
    * To prevent ininift loop we dont ecreate the function. Since this function is pointet to
    * when you click load, and it runs when you add something. Then it recreate and so on. Reqreate if
    * onlu sendregewst og wuote changes. */
    const addedCommentHandler = useCallback(() => {
        sendRequest(quoteId);
    }, [sendRequest, quoteId]);

    /* Common data holder for comments */
    let comments;

    if(status === 'pending') {
        /* IF comments loading we set comment data to spinner */
        comments = <div className='centered'><LoadingSpinner/></div>;
    }

    /* If done loading and we have data */
    if(status === 'completed' && loadedComments && loadedComments.length > 0) {
        comments = <CommentsList comments={loadedComments}/>;
    }

    /* If no comments */
    if(status === 'completed' && (!loadedComments || loadedComments.length === 0)) {
        comments = <p className='centered'>No comments are added yet</p>;
    }

    return (
        <section className={classes.comments}>
            <h2>User Comments</h2>
            {!isAddingComment && (
                <button className='btn' onClick={startAddCommentHandler}>
                    Add a Comment
                </button>
            )}
            {isAddingComment && <NewCommentForm quoteId={quoteId} onAddedComment={addedCommentHandler}/>}
            {comments}
        </section>
    );
};

export default Comments;
