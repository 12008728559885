import {Route, useParams} from "react-router";
import {Fragment, useEffect} from "react";
import Comments from "../components/comments/Comments";
import HighlightedQuote from "../components/quotes/HighlightedQuote";

/* Using routematch to get current url so we dont need to write nestet path  */
import {Link, useRouteMatch} from "react-router-dom";
import useHttp from "../hooks/use-http";
import {getSingleQuote} from "../lib/api";
import LoadingSpinner from "../components/UI/LoadingSpinner";

const QuoteDetail = () => {
    /* Get acces to parms from url /somethign/:parma1/somethingmore/:parma2 and so on. */
    const params = useParams();

    /* Getting current ID using desctruitiong here,
    * since if we use the parmas in the sendrequest witch is inside a
    * use effect that would mean that each time any params change
    * this will run once more. */
    const { quoteId } = params;

    /* Getting useRouth match */
    const match = useRouteMatch();

    /* Getting data */
    const {sendRequest, status, data: loadQuote, error } = useHttp(getSingleQuote, true);

    /* Run on start */
    useEffect(() => {
        sendRequest(quoteId);
    }, [sendRequest, quoteId]);

    /* Loggin ti exame whats inside route match
    * Turns out we get the current URL in match.url.
    * We can then use this if we want ti add extra path to this URL
    * so we dont have to change to much.
    *
    * Path gives us the same as URL except we get the rout definition
    * such as :quoteID and not the current parameter. */
    console.log(match);

    /* Loading status */
    if(status === 'pending') {
        return (
          <div className='centered'>
              <LoadingSpinner />
          </div>
        );
    }

    if(error) {
        return <p className='centered'>{error}</p>
    }

    if(!loadQuote.text) {
        return <p>No Quote Found!</p>;
    }


    /* Comment Link here USE PATH not URL
    * If you want to get the parms later on in a children or other component,
    * the url remove params from the string so it cant be used. */
    const commentLink = `${match.path}/comments`;

    return (
        <Fragment>
            <HighlightedQuote text={loadQuote.text} author={loadQuote.author}/>

            {/* Using route to add or not add content, if we are  on the page but without comments we load show comments
              * if not, we remove it since we are not on the page */}
            <Route path={match.url} exact>
                {/* Link to add comments*/}
                <div className='centered'>
                    <Link className='btn--flat' to={commentLink}>Load Comments</Link>
                </div>
            </Route>

            {/* Nested Route  if comments are last in url displat comments for that id */}
            <Route path={commentLink} exact>
                <Comments/>
            </Route>
        </Fragment>
    );
};

export default QuoteDetail;