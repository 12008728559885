import React, {Suspense} from "react";

/* IMport switch and route react-router*/
import {Redirect, Route, Switch} from "react-router";
import AllQuotes from "./pages/AllQuotes";
import QuoteDetail from "./pages/QuoteDetail";
import Layout from "./components/layout/Layout";
import NotFound from "./pages/NotFound";
import LoadingSpinner from "./components/UI/LoadingSpinner";


/* Using LazyLoading splitting the code up
* This code is now only loaded when we visit the NewQuotes page.
* Only problem is that, when we do that. It can take some secounds before it loads
* or milisecounds and react have no other page to show in the mean time or
* fallback code so we need to provid this.  */
const NewQuotes = React.lazy(() => import('./pages/NewQuotes'));

/* The soslution to lazyLoading is the suspense import from react libray.
* Wrap the whole code in it. And provide the fallback code. If it dosent load or something
* happen this will be shown when loading. */

function App() {
    return (
        <Layout>
            <Suspense fallback={<div className='centered'><LoadingSpinner /></div>}>
                <Switch>
                    <Route path='/' exact>
                        <Redirect to="/quotes"/>
                    </Route>

                    {/* Rember to use exact since we have uniqe qite urls after this one */}
                    <Route path="/quotes" exact>
                        <AllQuotes/>
                    </Route>

                    {/* Dynamic string */}
                    <Route path="/quotes/:quoteId">
                        <QuoteDetail/>
                    </Route>

                    <Route path="/new-quotes">
                        <NewQuotes/>
                    </Route>

                    {/* Fallback route, this is the last check point, so if no path before this was found
                  * We can add id here with a star. So it becomes our 404 page. If the page is not found
                  * We set it our self here with a match all. That is only considred if not other pages are found */}
                    <Route path='*'>
                        <NotFound/>
                    </Route>
                </Switch>
            </Suspense>
        </Layout>
    );
}

export default App;
