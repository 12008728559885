import QuoteList from "../components/quotes/QuoteList";

/* Fetch imports */
import useHttp from "../hooks/use-http";
import {getAllQuotes} from "../lib/api";
import {useEffect} from "react";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import NoQuotesFound from "../components/quotes/NoQuotesFound";

const AllQuotes = () => {
    const {sendRequest, status, data: loadedQuotes, error} = useHttp(getAllQuotes, true);

    /* Run once on start up */
    useEffect(() => {
        sendRequest();
    }, [sendRequest])

    /* Show spinner when loading*/
    if(status === 'pending') {
        return <div className='centered'><LoadingSpinner/></div>
    }

    /* Show error */
    if(error) {
        return <p className='centered focused'>{error}</p>
    }

    /* If we are done, and loaded quotes are false or have alength of zero - return no quotes found */
    if(status === 'completed' && (!loadedQuotes || loadedQuotes.length === 0 )) {
        return <NoQuotesFound />
    }

    /* If all went fine we out pute the quotes. */
    return (
        <QuoteList quotes={loadedQuotes} />
    );
};

export default AllQuotes;