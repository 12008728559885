import {useEffect, useRef} from 'react';

import classes from './NewCommentForm.module.css';
import useHttp from "../../hooks/use-http";
import {addComment} from "../../lib/api";
import LoadingSpinner from "../UI/LoadingSpinner";

const NewCommentForm = (props) => {
    const commentTextRef = useRef();

    const {sendRequest, status, error} = useHttp(addComment);

    /* Get this function throug prop so we can call this function
    * when we are done loading to notify the parent component. */
    const {onAddedComment} = props;

    /* Usinf ise effect to notifi parent component that we are done.
    * When status changes we run this .*/
    useEffect(() => {
        if (status === 'completed' && !error) {
            onAddedComment();
        }
    }, [status, error, onAddedComment]);

    const submitFormHandler = (event) => {
        event.preventDefault();

        /* Get data with ref */
        const enteredText = commentTextRef.current.value;

        /* Getting quote id with value from url  is an options
        * using params. But We do here expextct to get it with props
        * so the code are more flexibile. Using the url we say that this
        * component can only be used where there are urls handeling that sort of data. */

        console.log(props.quoteId);

        // send comment to server
        sendRequest({commentData: {text: enteredText}, quoteId: props.quoteId});
    };

    return (
        <form className={classes.form} onSubmit={submitFormHandler}>
            {status === 'pending' && (
                <div className='centered'>
                    <LoadingSpinner/>
                </div>
            )}
            <div className={classes.control} onSubmit={submitFormHandler}>
                <label htmlFor='comment'>Your Comment</label>
                <textarea id='comment' rows='5' ref={commentTextRef}></textarea>
            </div>
            <div className={classes.actions}>
                <button className='btn'>Add Comment</button>
            </div>
        </form>
    );
};

export default NewCommentForm;
