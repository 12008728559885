import {Fragment} from 'react';

import QuoteItem from './QuoteItem';
import classes from './QuoteList.module.css';
import {useHistory, useLocation} from "react-router-dom";

const sortQuotes = (quotes, ascending) => {
    return quotes.sort((quoteA, quoteB) => {
        if (ascending) {
            return quoteA.id > quoteB.id ? 1 : -1;
        } else {
            return quoteA.id < quoteB.id ? 1 : -1;
        }
    });
};

const QuoteList = (props) => {
    /* Histor import for navigation */
    const history = useHistory();

    /* Get loaction data, what in the url as for exampel query parameters */
    const location = useLocation()

    /* BUilt inn constructur function built into browser javascript class
    * search paramter in location contains the ? so we pass it to URL Serach Params.
    * This will create a oobject with all the parameter in the ? this will create a key with
    * in our case the sort key. */
    const queryParams = new URLSearchParams(location.search);

    /* How do we sort, using get if sort dosent exist return false and that equals not to asc so win win */
    const isSortingAscending = queryParams.get('sort') === 'asc';

    /* Sort the list */
    const sortedQuotes = sortQuotes(props.quotes, isSortingAscending);

    console.log(location);

    const changeSortingHandler = () => {
        /* Pushing sorting based on earlier click to query parameter ?*/
        history.push(`${location.pathname}?sort=${(isSortingAscending ? 'desc' : 'asc')}`);
    };

    return (
        <Fragment>
            {/* Sorting button for Query Paramterer */}
            <div className={classes.sorting}>
                <button onClick={changeSortingHandler}>Sort {isSortingAscending ? 'Descending' : 'Ascending'}</button>
            </div>
            <ul className={classes.list}>
                {sortedQuotes.map((quote) => (
                    <QuoteItem
                        key={quote.id}
                        id={quote.id}
                        author={quote.author}
                        text={quote.text}
                    />
                ))}
            </ul>
        </Fragment>
    );
};

export default QuoteList;
